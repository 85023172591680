var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.appId
          ? _vm.$t("lang_edit_application")
          : _vm.$t("lang_new_application"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _vm.dialogVisible
        ? _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                model: _vm.appData,
                rules: _vm.formRules,
                "label-position": "top",
                "label-suffix": ": ",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_apply_name"),
                                prop: "name",
                              },
                            },
                            [
                              _c("lang-input", {
                                key: "lang_apply_name",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lang_please_enter_app_name"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.appData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "name", $$v)
                                  },
                                  expression: "appData.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_application_coding"),
                                prop: "code",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lang_please_enter_the_application_code"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.appData.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "code", $$v)
                                  },
                                  expression: "appData.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_parent_description"),
                                prop: "description",
                              },
                            },
                            [
                              _c("lang-input", {
                                key: "lang_parent_description",
                                attrs: {
                                  type: "textarea",
                                  clearable: "",
                                  autosize: { minRows: 2, maxRows: 4 },
                                  placeholder: _vm.$t(
                                    "lang_please_enter_application_description"
                                  ),
                                },
                                model: {
                                  value: _vm.appData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "description", $$v)
                                  },
                                  expression: "appData.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_applicatio_sorting"),
                                prop: "sort",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  precision: 0,
                                  step: 1,
                                  min: 0,
                                  placeholder: _vm.$t(
                                    "lang_please_enter_app_sort"
                                  ),
                                },
                                model: {
                                  value: _vm.appData.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "sort", $$v)
                                  },
                                  expression: "appData.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "applicationTypeId",
                                label: _vm.$t("lang_application_type"),
                                prop: "applicationTypeId",
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.appTypeTreeData,
                                  "append-to-body": "",
                                  "z-index": 2999,
                                  "default-expand-level": 1,
                                  normalizer: _vm.appTypeTreeNormalizer,
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.appData.applicationTypeId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appData,
                                      "applicationTypeId",
                                      $$v
                                    )
                                  },
                                  expression: "appData.applicationTypeId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_app_home_page"),
                                prop: "homePage",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lang_please_enter_the_application_home_page"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.appData.homePage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "homePage", $$v)
                                  },
                                  expression: "appData.homePage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                label: _vm.$t("lang_application_Icon"),
                                prop: "icon",
                              },
                            },
                            [
                              _c("glb-img-upload", {
                                attrs: {
                                  "is-detail": false,
                                  "secret-flag": false,
                                  "upload-url": _vm.uploadUrl,
                                  "pub-preview-url": _vm.pubPreviewUrl,
                                },
                                model: {
                                  value: _vm.appData.icon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appData, "icon", $$v)
                                  },
                                  expression: "appData.icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }