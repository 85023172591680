





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BindScope from '@/components/bind-scope/index.vue';
import { RoleSelectDeptUserModel } from '@/models/system/RoleModel';
import AppApi from '@/api/application/AppApi';

@Component({
  name: 'AppConfigScope',
  components: {
    BindScope
  }
})
export default class AppConfigScope extends Vue {
  // prop
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 应用id
  @Prop({ default: '' }) private appId!: string;

  // 加载
  private formLoading: boolean = false;

  // 选择的部门、用户列表
  private selectedScopeList: RoleSelectDeptUserModel[] = [];

  /**
   * 接收组件传过来的值
   * @param val
   */
  updateSelectDeptUser(val) {
    this.selectedScopeList = val;
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.formLoading = true;
      AppApi.getApplicationScope({ applicationId: this.appId })
        .then((res: Record<any, any>) => {
          let data = res.data;
          if (res.code === '1' && data) {
            let groupList = data.groupList;
            let userList = data.userList;
            if (groupList) {
              groupList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'org',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }

            if (userList) {
              userList.forEach(item => {
                let row: RoleSelectDeptUserModel = {
                  id: item.id,
                  type: 'user',
                  name: item.name
                };
                this.selectedScopeList.push(row);
              });
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    } else {
      this.selectedScopeList = [];
    }
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.updateVisible(false);
    this.$emit('close');
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    // 构建请求数据
    let userIds: any[] = [];
    let groupIds: any[] = [];
    this.selectedScopeList.forEach(item => {
      if (item.type === 'user') {
        userIds.push(item.id);
      } else if (item.type === 'org') {
        groupIds.push(item.id);
      }
    });
    let params = { id: this.appId, scope: { userIds, groupIds } };

    AppApi.setApplicationScope(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        // 关闭弹窗
        this.handleCloseDialog();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
