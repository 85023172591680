









import { Vue, Component, Watch, Prop, Model, Emit } from 'vue-property-decorator';
import * as LanguageApi from '@/api/system/LanguageApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import { langStorageKey, langTypeStorageKey, curLangStorageKey } from '@/lang';
import LangDialog from './dialog.vue';
import _ from 'lodash';
// 翻译文本，接受文字，翻译成对应语言
@Component({
  name: 'GlbLang',
  components: { LangDialog }
})
export default class LangInput extends Vue {
  // @Prop({ type: String }) value: any;
  @Model('value') value!: string;
  @Prop()
  placeholder?: string;
  @Prop()
  size?: string;
  @Prop({ default: false })
  disabled?: boolean;
  langData: any = { code: '', content: [], staticFlag: false };
  dialogVisible: boolean = false;

  @Emit('value')
  changeLang(value) { }

  created() {
    this.buildLangData()
  }

  /**
   * 回显时，根据code构建数据 
   */
  buildLangData() {
    let langData = { code: '', content: [], staticFlag: false }
    let langTypes = StorageUtil.getLocalStorage(langTypeStorageKey)
    if (this.value) {
      langData.code = this.value;
      let langs = StorageUtil.getLocalStorage(langStorageKey);
      for (const langKey in langs) {
        let langItems = langs[langKey];
        let langId = langTypes.find(item => item.code == langKey).id
        let contentItem = { languageType: langId, content: langItems[this.value] }
        langData.content.push(contentItem)
      }
    } else {
      langTypes.forEach(typeItem => {
        let contentItem = { languageType: typeItem.id, content: '' }
        langData.content.push(contentItem)
      });
    }
    this.langData = langData;
  }
  /**
   * 语言内容发生变化时，根据code判断进行添加或更新操作 
   */
  handleInput: Function = _.throttle((that, value) => {
    let langData = SystemUtil.cloneDeep(that.langData)
    let langTypes = StorageUtil.getLocalStorage(langTypeStorageKey);
    let curLang = StorageUtil.getLocalStorage(curLangStorageKey);
    if (langData.code) {
      langData.content.forEach(contentItem => {
        let curLangId = langTypes.find(typeItem => typeItem.code == curLang).id
        if (contentItem.languageType == curLangId) {
          contentItem.content = value;
        }
      })
      LanguageApi.languageResourceUpdate(langData).then((res: any) => {
        if (res.code == '1') {
          that.langData = langData;
          that.updateI18n(langData)
          that.changeLang(langData.code);
        }
      });
    } else {
      langData.code = 'lang_auto_' + SystemUtil.uuid();
      let contentData = [];
      langTypes.forEach(typeItem => {
        let contentItem = { languageType: typeItem.id, content: '' };
        if (typeItem.code == curLang) {
          contentItem.content = value;
        }
        contentData.push(contentItem)
      })
      langData.content = contentData;
      LanguageApi.languageResourceAdd(langData).then((res: any) => {
        if (res.code == '1') {
          that.langData = langData;
          that.updateI18n(langData)
          that.changeLang(langData.code);
        }
      });
    }
  }, 500);

  /**
   * 更新本地多语言库 
   */
  updateI18n(langData) {
    let curLang = StorageUtil.getLocalStorage(curLangStorageKey);
    let langTypes = StorageUtil.getLocalStorage(langTypeStorageKey);
    this.$i18n.mergeLocaleMessage(curLang, langData.content);
    langData.content.forEach(contentItem => {
      let langCode = langTypes.find(typeItem => typeItem.id === contentItem.languageType)!.code;
      let mergeData = { [langData.code]: contentItem.content };
      this.$i18n.mergeLocaleMessage(langCode, mergeData);
    })
    StorageUtil.setLocalStorage(langStorageKey, this.$i18n.messages, null);
  }

  /**
   * 弹出多语言配置弹窗 
   */
  openDialog() {
    this.dialogVisible = true;
  }

  /**
   * 弹窗配置成功后 
   */
  handleSuccess(langData) {
    if (this.disabled) {
      return true;
    }
    this.langData = langData;
    this.changeLang(langData.code);
    this.dialogVisible = false;
  }
}
