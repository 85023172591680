var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        {
          attrs: {
            disabled: _vm.disabled,
            value: _vm.$t(_vm.value),
            placeholder: _vm.placeholder,
            size: _vm.size,
          },
          on: {
            input: function (val) {
              return _vm.handleInput(this$1, val)
            },
          },
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-setting" },
            on: { click: _vm.openDialog },
            slot: "append",
          }),
        ],
        1
      ),
      _c("lang-dialog", {
        attrs: { visible: _vm.dialogVisible, data: _vm.langData },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          success: _vm.handleSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }