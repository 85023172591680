





































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import GlbLang from '@/components/glb-lang/input.vue';
import AppApi from '@/api/application/AppApi';
import AppTypeApi from '@/api/application/AppTypeApi';

@Component({
  name: 'AppForm',
  components: { GlbLang }
})
export default class AppForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // 应用id
  @Prop({ default: '' }) appId!: string;
  // 应用类型id
  @Prop({ default: '' }) appTypeId!: string;

  // 图片组件上传地址
  uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  pubPreviewUrl: string = '/river-fs/file/view';

  // 应用新增/编辑表单实体
  appData: any = {};
  // 表单验证规则
  formRules: Record<string, any> = {
    name: { required: true, type: 'string', message: this.$t('lang_please_enter_app_name'), trigger: 'blur' },
    code: { required: true, type: 'string', message: this.$t('lang_please_enter_the_application_code'), trigger: 'blur' },
    sort: { required: true, type: 'number', message: this.$t('lang_please_enter_app_sort'), trigger: 'blur' }
  };

  // App类型树列表
  appTypeTreeData: any[] = [];

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      if (this.appId) {
        // 获取应用详情
        this.getAppDetailData();
      }

      if (this.appTypeId) {
        this.appData.applicationTypeId = this.appTypeId;
      }

      // 获取App类型树
      this.getAppTypeTreeData();
    } else {
      // 清空表单内容
      this.appData = {};
      // 清理表单验证
      this.formRef.resetFields();
    }
  }

  /**
   * 获取App类型树
   */
  getAppTypeTreeData() {
    let params = {
      name: ''
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appTypeTreeData = [{ id: '-1', displayName: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 获取应用详情
   */
  getAppDetailData() {
    AppApi.detail({ applicationId: this.appId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appData = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.updateVisible(false);
    this.$emit('close');
    this.$emit('getTreeData');
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  async saveForm() {
    // 验证表单
    await this.formRef.validate();
    let param = Object.assign({}, this.appData);
    // param.applicationTypeId = this.appTypeId;
    AppApi.save(param)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        // 关闭弹窗
      });
  }

  /**
   * 下拉选转换prop
   * @param node
   */
  appTypeTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.displayName,
      children: node.children
    };
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
