var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("treeselect", {
    attrs: {
      options: _vm.appTypeTreeData,
      "append-to-body": "",
      "z-index": 2999,
      "default-expand-level": 1,
      normalizer: _vm.appTypeTreeNormalizer,
      placeholder: "",
    },
    model: {
      value: _vm.innerAppTypeId,
      callback: function ($$v) {
        _vm.innerAppTypeId = $$v
      },
      expression: "innerAppTypeId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }