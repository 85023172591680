












import AppTypeApi from '@/api/application/AppTypeApi';
import { Component, Prop, Vue, Ref, Watch, Model, Emit } from 'vue-property-decorator';
@Component({
  name: 'app-type-tree',
  components: {}
})
export default class AppTypeTree extends Vue {
  // App类型树列表
  appTypeTreeData: any[] = [];

  innerAppTypeId: string = '';
  // App类型ID
  @Model('change') appTypeId!: string;

  @Emit('change')
  change(appTypeId: string) {}
  created() {
    this.innerAppTypeId = this.appTypeId;
    this.getAppTypeTreeData();
  }
  @Watch('appTypeId', { deep: true })
  onVariableNameChange(appTypeId: string) {
    this.innerAppTypeId = appTypeId;
  }

  @Watch('innerAppTypeId', { deep: true })
  onInnerVariableNameChange(innerAppTypeId: string) {
    this.change(innerAppTypeId);
  }
  /**
   * 获取App类型树
   */
  getAppTypeTreeData() {
    let params = {
      name: ''
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appTypeTreeData = [{ id: '-1', displayName: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 下拉选转换prop
   * @param node
   */
  appTypeTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.displayName,
      children: node.children
    };
  }
}
