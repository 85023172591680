import HttpUtil from '@/utils/HttpUtil'

export default {

  // 获取应用类型树
  tree(params) {
    var url = process.env.VUE_APP_SERVER_CODE + "application/type/query/admin/list"
    return HttpUtil.post(url, params);
  },

  // 获取应用树
  applicationLinkQueryCodeTree(param) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/code/tree';
    return HttpUtil.get(url, param);
  },

  // 获取分类
  types() {
    var url = process.env.VUE_APP_SERVER_CODE + "application/type/query/types"
    return HttpUtil.get(url);
  },

  // 保存
  save(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "application/type/save";
    return HttpUtil.post(url, params);
  },

  // 删除
  delete(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "application/type/delete";
    return HttpUtil.get(url, params);
  },

  // 详情
  detail(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "application/type/query/single";
    return HttpUtil.get(url, params);
  }

}
