






















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import * as LanguageApi from '@/api/system/LanguageApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import { langStorageKey, langTypeStorageKey, curLangStorageKey } from '@/lang';
@Component({
  name: 'GlbLangDialog'
})
export default class LangDialog extends Vue {
  @Prop({
    default: false
  })
  visible!: boolean;
  @Prop()
  data!: any;
  langContent: Record<string, any>[] = [];
  created() {
    this.langContent = SystemUtil.cloneDeep(this.data.content);
  }
  // 获取语言类型名称
  getLangTypeName(langType) {
    let langTypes = StorageUtil.getLocalStorage(langTypeStorageKey);
    let target = langTypes.find(typeItem => typeItem.id === langType);
    return target?.name;
  }
  /**
   * 保存语言项 
   */
  saveLangData() {
    let langData = SystemUtil.cloneDeep(this.data)
    langData.content = this.langContent;
    if (langData.code) {
      LanguageApi.languageResourceUpdate(langData).then((res: any) => {
        if (res.code == '1') {
          this.updateI18n(langData);
          this.$emit('success', langData)
        }
      });
    } else {
      langData.code = 'lang_auto_' + SystemUtil.uuid();
      LanguageApi.languageResourceAdd(langData).then((res: any) => {
        if (res.code == '1') {
          this.updateI18n(langData);
          this.$emit('success', langData)
        }
      });
    }
  }
  /**
   * 更新本地多语言库 
   */
  updateI18n(langData) {
    let curLang = StorageUtil.getLocalStorage(curLangStorageKey);
    let langTypes = StorageUtil.getLocalStorage(langTypeStorageKey);
    this.$i18n.mergeLocaleMessage(curLang, langData.content);
    langData.content.forEach(contentItem => {
      let langCode = langTypes.find(typeItem => typeItem.id === contentItem.languageType)!.code;
      let mergeData = { [langData.code]: contentItem.content };
      this.$i18n.mergeLocaleMessage(langCode, mergeData);
    })
    StorageUtil.setLocalStorage(langStorageKey, this.$i18n.messages, null);
  }
  /**
   * 关闭弹窗 
   */
  closeDialog() {
    this.$emit('update:visible', false)
  }

  @Watch('visible')
  onVisChange(v) {
    if (v) {
      this.langContent = SystemUtil.cloneDeep(this.data.content);
    }
  }
}
