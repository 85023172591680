





































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import AppTypeApi from '@/api/application/AppTypeApi';

@Component({
  name: 'CurAppTypeForm',
  components: {}
})
export default class CurAppTypeForm extends Vue {
  // ref
  @Ref() readonly curAppTypeRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // App 类型id
  @Prop({ default: '' }) appTypeId!: string;

  // 应用类型实体
  curAppType: any = {};

  // 图片组件上传地址
  uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  pubPreviewUrl: string = '/river-fs/file/view';

  // 表单验证规则
  formRules: Record<string, any> = {
    name: [{ required: true, message: this.$t('lang_fill_in_application_type_name'), tirgger: 'blur' }],
    code: [{ required: true, message: this.$t('lang_fill_in_application_type_code'), tirgger: 'blur' }],
    sort: [{ required: true, type: 'number', message: this.$t('lang_fill_in_the_sort'), tirgger: 'blur' }],
    pid: [{ required: true, message: '请选择父级应用类型', tirgger: 'blur' }],
    type: [{ required: true, message: this.$t('lang_select_application_type_classification'), tirgger: 'blur' }]
  };
  // 应用类型列表数据
  appTypeTypeListData: any[] = [];

  // App类型树列表
  appTypeTreeData: any[] = [];

  /**
   * 获取App类型树
   */
  getAppTypeTreeData() {
    let params = {
      name: ''
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appTypeTreeData = [{ id: '-1', displayName: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 获取App类型树
   */
  getAppTypeDetail() {
    AppTypeApi.detail({ applicationTypeId: this.appTypeId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        let data = res.data;
        // 单独处理status字段
        data.status = data.status === '1';
        this.curAppType = data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      // 判断App类型id是否存在
      if (this.appTypeId) {
        // 查询App类型详情
        this.getAppTypeDetail();
      } else {
        this.curAppType = {};
      }
    }
  }

  /**
   * 下拉选转换prop
   * @param node
   */
  appTypeTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.displayName,
      children: node.children
    };
  }

  appTypeClassifyTreeNormalizer(node) {
    return {
      id: node.code,
      label: node.name,
      children: node.children
    };
  }

  created() {
    // 获取App类型树
    this.getAppTypeTreeData();

    // 查询类型分类
    this.getAppTypeTypeListData();
  }

  /**
   *  查询类型分类
   */
  getAppTypeTypeListData() {
    AppTypeApi.types().then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appTypeTypeListData = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  //保存
  async saveFormData() {
    // 验证表单
    await this.curAppTypeRef.validate();
    // 请求参数
    let param = Object.assign({}, this.curAppType);
    param.status = param.status ? '1' : '0';
    AppTypeApi.save(param)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {});
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 关闭弹窗
  handleCloseDialog() {
    // 清除表单验证
    this.curAppTypeRef.clearValidate();
    this.updateVisible(false);
    this.$emit('close');
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
